.icreader .page-header .img {
  max-width: 88px;
}
.icreader__block {
  margin: 0;
  padding: 35px 15px;
}
.icreader__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.icreader__block__ttl {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
}
.icreader__block__lead {
  margin-top: 30px;
}
.icreader__block ol > li {
  margin-left: 1.5em;
  list-style: decimal outside;
}
.icreader__anchor {
  margin: -15px auto 10px;
}
.icreader__cont {
  margin-top: 40px;
}
.icreader__cont__ttl {
  font-size: 18px;
  text-align: center;
}
.icreader__cont__subTtl {
  margin-top: 22px;
  padding-left: 13px;
  color: #0072BC;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.05;
  position: relative;
}
.icreader__cont__subTtl::before {
  content: "";
  display: block;
  width: 3px;
  height: 15px;
  background-color: #0072BC;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.icreader__cont__lead {
  margin-top: 20px;
}
.icreader__cont__name {
  margin-top: 28px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.icreader__cont table {
  margin-top: 25px;
}
.icreader__cont .notice {
  margin-top: 38px;
}

@media screen and (min-width: 768px) {
  .icreader .page-header .img {
    max-width: 174px;
    right: 13px;
  }
  .icreader__block {
    padding: 75px 15px 87px;
  }
  .icreader__block__ttl {
    font-size: 35px;
  }
  .icreader__block__lead {
    margin-top: 56px;
    text-align: center;
  }
  .icreader__anchor {
    margin: -15px auto 10px;
  }
  .icreader__cont {
    margin-top: 75px;
  }
  .icreader__cont__ttl {
    font-size: 24px;
    text-align: center;
  }
  .icreader__cont__subTtl {
    margin-top: 44px;
    padding-left: 22px;
    font-size: 20px;
    letter-spacing: 0.05em;
  }
  .icreader__cont__subTtl::before {
    width: 7px;
    height: 24px;
  }
  .icreader__cont__lead {
    margin-top: 34px;
    text-align: center;
  }
  .icreader__cont__name {
    margin-top: 0;
    font-size: 24px;
    text-align: left;
  }
  .icreader__cont table {
    margin-top: 25px;
  }
  .icreader__cont .notice {
    margin-top: 38px;
  }
}
@media screen and (min-width: 768px) {
  .intro .leadTxt {
    text-align: center;
  }
}
.icreader01 {
  padding: 64px 15px 55px;
}
.icreader01 table.tbl_1 {
  margin-top: 25px;
}
.icreader01 table.tbl_1 thead th {
  height: 55px;
}
.icreader01 table.tbl_1 thead th.blue {
  background-color: #0072BC;
}
.icreader01 table.tbl_1 thead tr:nth-child(1) th:nth-child(3) {
  width: 15%;
}
.icreader01 table.tbl_1 thead tr:nth-child(1) th:nth-child(4) {
  border-bottom: 1px solid #FFF;
  width: 30%;
}
.icreader01 table.tbl_1 tbody th {
  background-color: #F1F6F9;
}
.icreader01 table.tbl_1 tbody td.blue {
  color: #0072BC;
}
.icreader01 table.tbl_2 {
  margin-top: 22px;
}
.icreader01 table.tbl_2 tbody th, .icreader01 table.tbl_2 tbody td {
  height: 59px;
}
.icreader01 table.tbl_2 tbody th {
  background-color: #F1F6F9;
}
.icreader01 table.tbl_3 {
  margin-top: 22px;
}
.icreader01 table.tbl_4 {
  margin-top: 22px;
}
.icreader01 .cont_1 .notice {
  margin-top: 38px;
}
.icreader01 .cont_2 {
  margin-top: 50px;
}
.icreader01 .cont_2 .notice {
  margin-top: 36px;
}
.icreader01 .cont_2 .notice ul {
  margin-top: 4px;
}
.icreader01 .cont_3 {
  margin-top: 48px;
}
.icreader01 .cont_3 .icreader__cont__txt01 {
  margin-top: 10px;
}
.icreader01 .cont_3 .icreader__cont__txt02 {
  margin-top: 12px;
}
.icreader01 .cont_3 .icreader__cont__txt03 {
  margin-top: 12px;
  font-size: 13px;
}
.icreader01 .cont_4 {
  margin-top: 48px;
}
.icreader01 .cont_4 .icreader__cont__txt01 {
  margin-top: 18px;
}

@media screen and (min-width: 768px) {
  .icreader01 {
    padding: 115px 15px 120px;
  }
  .icreader01 table.tbl_1 {
    margin-top: 35px;
  }
  .icreader01 .cont_1 {
    margin: 75px auto 0;
  }
  .icreader01 .cont_1 .notice {
    margin-top: 51px;
  }
  .icreader01 .cont_2 {
    margin: 96px auto 0;
    max-width: 900px;
  }
  .icreader01 .cont_2 .notice {
    margin-top: 56px;
  }
  .icreader01 .cont_2 .notice ul {
    margin-top: 13px;
  }
  .icreader01 .cont_3 {
    margin: 95px auto 0;
    max-width: 900px;
  }
  .icreader01 .cont_3 .icreader__cont__txt01 {
    margin-top: 17px;
  }
  .icreader01 .cont_3 .icreader__cont__txt02 {
    margin-top: 17px;
  }
  .icreader01 .cont_3 .icreader__cont__txt03 {
    margin-top: 16px;
  }
  .icreader01 .cont_4 {
    margin: 91px auto 0;
    max-width: 900px;
  }
  .icreader01 .cont_4 .icreader__cont__txt01 {
    margin-top: 33px;
    text-align: center;
  }
}
.icreader02 {
  padding: 64px 15px 50px;
  background-color: #F1F6F9;
}
.icreader02 table.tbl_3 {
  margin-top: 22px;
}
.icreader02 table thead tr:nth-child(1) th:nth-child(3) {
  border-bottom: 1px solid #FFF;
}
.icreader02 table thead tr:nth-child(2) th:nth-child(3) {
  border-right: 1px solid #FFF;
}
.icreader02 .cont_1 {
  margin-top: 28px;
}
.icreader02 .cont_1 .icreader__cont__feature {
  margin-top: 15px;
}
.icreader02 .cont_1 .feature__list + .feature__list {
  margin-top: 20px;
}
.icreader02 .cont_2 {
  margin-top: 36px;
}
.icreader02 .cont_3 {
  margin-top: 34px;
}
.icreader02 .cont_3 .notice {
  margin-top: 34px;
}

@media screen and (min-width: 768px) {
  .icreader02 {
    padding: 115px 15px 110px;
  }
  .icreader02 table.tbl_1 {
    margin-top: 35px;
  }
  .icreader02 table.tbl_1 tbody th {
    background-color: #F1F6F9;
  }
  .icreader02 table.tbl_1 tbody a {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .icreader02 .cont_1 {
    margin: 60px auto 0;
  }
  .icreader02 .cont_1 .flexBox {
    display: flex;
    justify-content: space-between;
  }
  .icreader02 .cont_1 .flexItem.img {
    width: 41%;
  }
  .icreader02 .cont_1 .flexItem.textarea {
    width: 52%;
  }
  .icreader02 .cont_1 .icreader__cont__feature {
    margin-top: 50px;
  }
  .icreader02 .cont_2 {
    margin: 56px auto 0;
    max-width: 900px;
  }
  .icreader02 .cont_2 .icreader__cont__txt01 {
    text-align: center;
  }
  .icreader02 .cont_3 {
    margin: 80px auto 0;
    max-width: 900px;
  }
}